import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Events from '../views/Events.vue'
import AllEvents from '../views/AllEvents.vue'
import Formitable from '../views/Formitable.vue'
import Reflex from '../views/Reflex.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/events/:id/:slug/:uid',
    name: 'events',
    component: Events
  },

  {
    path: '/allevents',
    name: 'allevents',
    component: AllEvents
  },
  {
    path: '/formitable/:uid',
    name: 'formitable',
    component: Formitable
  },
  {
    path: '/reflex/:uid',
    name: 'reflex',
    component: Reflex
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
