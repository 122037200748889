<template>
  <div v-show="isLoaded">
    <Header />

    <div
      id="shop-frame"
      :data-url="'https://eventix.shop/' + id"
      style="max-width: 600px; margin: 0 auto"
    ></div>
  </div>
</template>

<script>
import Header from "@/components/general/Header.vue"

import controller from "@/db/controller/CRUD"

export default {
  components: {
    Header,
  },

  data: () => {
    return {
      id: null,
      isLoaded:false,
    }
  },

  computed: {},

  async created() {
    this.id = this.$route.params.id

    const data = await controller.get("formitable", this.$route.params.uid)
    this.uid = data.id
    this.logo = data.logo
    this.isLoaded = true
  },

  methods: {
    getParams(string, param) {
      const params = new Proxy(new URLSearchParams(string), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      return params[param]
    },
  },
  computed: {
    store: function() {
      return this.$store.state;
    },
    logo: {
      get() {
        return this.store.b2b.logo
      },
      set(value) {
        this.store.b2b.logo = value
      },
    },
    uid: {
      get() {
        return this.store.b2b.uid
      },
      set(value) {
        this.store.b2b.uid = value
      },
    },
  }
}
</script>

<style defer scoped>
.button-div {
  justify-content: flex-end;
}
</style>
