<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import variables from "@/legends/variables.json"
export default {
  created() {
    this.logo = variables["logo"]

    const favicon = document.getElementById("favicon")
    favicon.href = this.logo + "?v=2"


    const webclip = document.getElementById("webclip")
    webclip.href =  this.logo + "?v=2"

  },
  computed: {
    store: function () {
      return this.$store.state
    },
    logo: {
      get() {
        return this.store.b2b.logo
      },
      set(value) {
        this.store.b2b.logo = value
      },
    },
  },
}
</script>

<style>
#WpRmContainer {
  width: 100%;
}

.background {
  height: 90vh;
}

#app {
  width: 100vw;
}
</style>
