<template>
  <div>
    <Header />

    <div
      id="shop-frame"
      data-url="https://shop.eventix.io/046d7285-58e5-11ed-aa54-6a57c78572ab"
      style="max-width: 600px; margin: 0 auto"
    ></div>
  </div>
</template>

<script>
import Header from "@/components/general/Header.vue"

export default {
  components: {
    Header,
  },

  data: () => {
    return {}
  },

  computed: {},

  async created() {},

  methods: {
    getParams(string, param) {
      const params = new Proxy(new URLSearchParams(string), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      return params[param]
    },
  },
}
</script>

<style defer scoped>
.button-div {
  justify-content: flex-end;
}
</style>
