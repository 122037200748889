import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import firebase from "firebase/compat/app";


// Config
import config from "./firebase/config.js";

// Initializing firebase app
const app = firebase.initializeApp(config);

const db = app.firestore();
const auth = app.auth();


export { auth, db };
