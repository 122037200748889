import { createStore } from 'vuex'

export default createStore({
  state: {
    b2b: {
      url: "",
      logo: "",
      uid: ""
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
