<template>
  <div>
    <Header />
    <div
      class="background mozo wf-section"
      style="display: flex; justify-content: flex-end; align-items: flex-end"
    >
      <div
        v-if="isLoaded"
        class="bg-image-formitable"
        :style="
          'background-image: url(\' ' +
          bg_image +
          ' \');background-position: 50% 50%;background-size: cover;'
        "
      ></div>
      <div id="shop-frame" :data-url="url" style="max-width: 600px; margin: 0 auto"></div>
      <div
        class="ft-widget-b2"
        :data-restaurant="form_id"
        data-open="true"
        data-open-mobile="true"
        data-color="#000000"
        data-language="auto"
        data-tag="MOZO"
        data-toolbar="true"
        data-toolbar-mobile="true"
      ></div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/general/Header.vue"
import Footer from "@/components/general/Footer.vue"

import controller from "@/db/controller/CRUD"

export default {
  components: {
    Header,
    Footer,
  },

  data: () => {
    return {
      isLoaded: false,
      form_id: null,
      bg_image:
        "https://thumbs.dreamstime.com/z/white-rabbit-portrait-green-nature-background-38796273.jpg",
    }
  },


   async mounted() {
    
    try {
        const data = await controller.get("formitable", this.$route.params.uid);
        this.bg_image = data.bg_image;
        this.url = data.url;
        this.logo = data.logo;
        this.form_id = data.form_id;
        this.isLoaded = true;
        this.loadFormitableScript();
    } catch (error) {
        console.error('Failed to fetch data:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
    }
  },

  methods: {
    getParams(string, param) {
      const params = new Proxy(new URLSearchParams(string), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      return params[param]
    },
    loadFormitableScript() {
        const scriptId = "formitable-sdk";
        
        // Check if script is already present
        if (document.getElementById(scriptId)) return;

        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://cdn.formitable.com/sdk/v1/ft.sdk.min.js";
        script.onload = () => {
            FT.load("Analytics");
        };

        document.head.appendChild(script);
    }
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    b2b_uid: function () {
      return this.store.b2b.uid
    },
    url: {
      get() {
        return this.store.b2b.url
      },
      set(value) {
        this.store.b2b.url = value
      },
    },
    logo: {
      get() {
        return this.store.b2b.logo
      },
      set(value) {
        this.store.b2b.logo = value
      },
    },
  },
}
</script>

<style defer scoped>
.button-div {
  justify-content: flex-end;
}
.background.mozo {
  background-image: repeating-radial-gradient(
    circle farthest-corner at 100% 0%,
    #000000,
    #000000
  );
  background-position: 50% 30%, 0px 0px;
  background-repeat: repeat, repeat;
  background-attachment: fixed, scroll;
  position: relative;
  border-radius: 8px 8px 0px 0px;
}

.bg-image-formitable {
  position: absolute;

  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: white;
}

.ft-widget-b2 {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .bg-image-formitable {
    width: 100%;
  }
}

.ft-widget-b2 {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin: auto;
  max-height: 60vh; /* Limiting the height */
  overflow-y: auto; /* Adding scroll in case content exceeds this height */
}

</style>
