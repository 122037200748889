import { db } from "@/db/index.js"

export default {
    async set(collection: string, b2b_uid: string, data: any) {
        await db
            .collection(collection)
            .doc(b2b_uid)
            .set(data)
            .catch((err: any) => {
                throw err.message;
            });
    },
    async update(collection: string, b2b_uid: string, data: any) {

        await db
            .collection(collection)
            .doc(b2b_uid)
            .update(data)
            .catch((err: any) => {
                throw err.message;
            });
    },
    async remove(collection: string, b2b_uid: string) {

        await db
            .collection(collection)
            .doc(b2b_uid)
            .delete()
            .catch((err: any) => {
                throw err.message;
            });
    },


    async get(collection: string, b2b_uid: string) {
        return await db
            .collection(collection)
            .doc(b2b_uid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data()!
                    data.id = doc.id
                    return data
                }
            })
            .catch((err: any) => {
                throw err.message;
            });

    },




}