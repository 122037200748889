<template>
  <div>
    <div v-if="isLoaded">
      <Header />

      <div
        id="RmwpWidgetContainer"
        style="
          width: 100%;
          height: 84vh;
          display: flex;
          justify-content: center;
          border-radius: 8px 8px 0px 0px;
          overflow: hidden;
        "
      ></div>
      <Footer :inputlogo="'/images/logos/MOZO_Logo_Zwart_Rechthoek.png'" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/general/Header.vue"
import Footer from "@/components/general/Footer.vue"

import controller from "@/db/controller/CRUD"

export default {
  components: {
    Header,
    Footer,
  },

  data: () => {
    return {
      isLoaded: true,
    }
  },

  computed: {},

  async created() {
    const data = await controller.get("reflex", this.$route.params.uid)
    this.url = data.url
    this.logo = data.logo
    this.isLoaded = true
  },

  methods: {
    getParams(string, param) {
      const params = new Proxy(new URLSearchParams(string), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      return params[param]
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    b2b_uid: function () {
      return this.store.b2b.uid
    },
    url: {
      get() {
        return this.store.b2b.url
      },
      set(value) {
        this.store.b2b.url = value
      },
    },
    logo: {
      get() {
        return this.store.b2b.logo
      },
      set(value) {
        this.store.b2b.logo = value
      },
    },
  },
}
</script>

<style defer scoped>
.button-div {
  justify-content: flex-end;
}
</style>
